import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEye, FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import AddEntree from './AddEntree';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import Select from 'react-select';
import NumberFormat from '../../components/NumberFormat';
import { FaBan, FaCheck, FaCircle } from 'react-icons/fa';
import ShowEntree from './ShowEntree';


const Entrees = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [entrees, setEntrees] = useState([]);
    const [showAddEntree, setShowAddEntree] = useState(false);
    const [showEntree, setShowEntree] = useState(false);
    const [caisseCentrales, setCaisseCenrales] = useState([]);
    const [selectedCaisseCentrale, setSelectedCaisseCentrale] = useState(null);
    const [inputValueCaisseCentrale, setValueCaisseCentrale] = useState('');
    const [selectedEntree, setSelectedEntree] = useState(null);

    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        caisseCentrale: '',

    });
    const navigate = useNavigate();

    const supprimer = (entree) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/entree/' + entree.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getEntrees();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    const handleInputChangeCaisseCentrale = value => {
        setValueCaisseCentrale(value);
    };

    // handle selection


    // handle selection
    const handleChangeCaisseCentrale = value => {
        setSelectedCaisseCentrale(value);
        setReq({ ...req, caisseCentrale: value.id });

    }

    useEffect(() => {
        if (user.role != null && (user.role.name === 'admin' || user.role.name === 'controleur')) {
            getCaisseCentrales();
        }
    }, [])

    const getCaisseCentrales = () => {
        axiosClient.get('/caisseCentrales')
            .then((data) => {
                setCaisseCenrales(data.data.caisseCentrales);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const closeAddEntree = () => {
        setShowAddEntree(false);
    }
    const closeShowEntree = () => {
        setShowEntree(false);
        setSelectedEntree(null);
    }
    const getEntrees = () => {
        setLoading(true);
        axiosClient.get('/entrees?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&caisseCentrale=' + req.caisseCentrale)
            .then(response => {
                setEntrees(response.data.entrees);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const valider = (entree) => {
        axiosClient.put('/validerEntree/' + entree.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const annuler = (entree) => {
        axiosClient.put('/annulerEntree/' + entree.id)
            .then((data) => {
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            <Header title="Entrées" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                {user.role && (user.role.name === 'admin' || user.role.name === 'controleur') ?
                    <div className="flex-col w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Caisse Centrale <span className='text-red-500'> *</span></label>
                        <Select className='mt-1 outline-1 ' placeholder="Caisse Centrale" cacheOptions defaultOptions value={selectedCaisseCentrale} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={caisseCentrales} onInputChange={handleInputChangeCaisseCentrale} onChange={handleChangeCaisseCentrale} />
                    </div>
                    :
                    <></>
                }
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getEntrees()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Entrees"
                        sheet="entrees"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />
                    </DownloadTableExcel>
                </div>
            </div>
            {
                user.role && user.role.name === 'ccaissier' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddEntree(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    :
                    <></>
            }
            <div className="flex my-2">
                <h1 className='text-red-500 font-bold'>Total: <NumberFormat valeur={entrees.reduce((n, { montant }) => n + parseFloat(montant), 0)} /> MRU</h1>
            </div>
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>Numéro</th>
                            <th>Date</th>
                            <th>Caisse Centrale</th>
                            <th>Source</th>
                            <th>Montant</th>
                            <th>Ref</th>
                            <th>État</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="7"><Loading /></td></tr></tbody> :
                        <tbody>
                            {entrees.map(o => (
                                <tr key={o.id}>
                                    <td>{o.num}</td>
                                    <td>{o.dateEntree}</td>
                                    <td>{o.caisseCentrale.nom}</td>
                                    <td>{o.source}</td>
                                    <td>{o.montant}</td>
                                    <td>{o.reference}</td>
                                    {
                                        o.valide === false ?
                                            <div className='flex justify-center items-center text-lg text-orange-500'>
                                                <FaCircle />
                                            </div>
                                            :
                                            <div className='flex justify-center content-center items-center text-lg text-lime-500'>
                                                <FaCircle />
                                            </div>
                                    }
                                    <td>
                                        <div className="flex gap-2">
                                            {user.role && (user.role.name === 'admin' || user.role.name === 'controleur') ?
                                                <>
                                                    {
                                                        o.valide === false ?
                                                            <ActionButton onClick={() => { valider(o) }} color="bg-green-500" icon={<FaCheck />} textColor="text-white" title="Valider" />
                                                            :
                                                            <>
                                                                {user.role && (user.role.name === 'admin' ) ?
                                                                    <ActionButton onClick={() => { annuler(o) }} color="bg-yellow-500" icon={<FaBan />} textColor="text-white" title="Annuler" />
                                                                    :
                                                                    <></>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <></>

                                            }
                                            <ActionButton onClick={() => {
                                                setSelectedEntree(o);
                                                setShowEntree(true);
                                            }} color="bg-blue-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                                            {user.role && (user.role.name === 'admin' || user.role.name === 'controleur') ?
                                                <>
                                                    {o.valide === false ?
                                                        <ActionButton onClick={() => { supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    }
                </table>
            </div>
            {
                showAddEntree ? <AddEntree onClose={closeAddEntree} /> : <></>
            }
            {
                showEntree ? <ShowEntree entree={selectedEntree} onClose={closeShowEntree} /> : <></>
            }

        </>
    )
}

export default Entrees